import React, { useState } from 'react';
import { User, Linkedin, Twitter, Globe, Youtube } from 'lucide-react';
import Modal from '../components/molecules/BioModal';
import FeedbackModal from '../components/molecules/FeedbackModal';
import FloatingButton from '../components/molecules/FloatingButton';

const teamMembers = [
  {
    name: "Radha Kulkarni",
    role: "Co-Founder",
    avtar: "https://media.licdn.com/dms/image/v2/D4D03AQHiMXkLyc4mpg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1710916154362?e=1732752000&v=beta&t=QPMbBg0RVQicO1rGATRETJV3xGCFsnCPslHFmrorQjo",
    bio: "The founder of Khoj(parent company of Taksh), which was very well received by The Big Red Group and was recognized as one of the 5 entrepreneurs who made it big as teenagers. Kathak student for past 14+ years and have performed at recognized stages. Also won first prize in Kathak solo among 300 participants in an esteemed interschool dance competition."
  },
  {
    name: "Amit Gavali",
    role: "Developer",
    avtar: "https://media.licdn.com/dms/image/v2/D4D03AQEkQiCcIdMuzw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1713543373215?e=1732752000&v=beta&t=kj7bwf2z6snVgnZNC8-Rn9qb8QJhuOpbbcXryZNmZD0",
    bio: "Amit is a passionate web enthusiast focused on becoming a full-stack master. He thrives on tackling complex challenges and ambitious projects, always buzzing with new ideas and ready to create something incredible. Humble and skilled in everything he does, Amit is eager to connect and push the boundaries of what can be achieved through coding. 🚀💻"
  },
  {
    name: "Sandesh Tangade",
    role: "Developer",
    avtar: "https://media.licdn.com/dms/image/v2/D4D03AQG8eSLCn4ilug/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1709729928998?e=1732752000&v=beta&t=erYU2UxghRS-Y5aLpXz4bVE0UBLelnwkPzi8ALencME",
    bio: "A proud farmer's son, an engineer at heart, persistent without showoff. He is a computer engineer specialized in frontend. Sandesh is one of those people with high integrity towards his work."
  },
  {
    name: "Ishan Khare",
    role: "Technical Writer, Management",
    avtar: "https://media.licdn.com/dms/image/v2/D4D03AQE1vBQNw_B6Rw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1720553784781?e=1732752000&v=beta&t=2O8QY-HzSBLmm-14KaefJVlttFs1coFqBKysYiA-90U",
    bio: "A Robotics and Automation student, ishan is eager to contribute to Industry 5.0 by training under industry experts. With over a decade of competitive swimming at the state and national levels, he values teamwork and discipline. Specializing in Robotics at MIT WPU, Pune, he completed an internship at 3D Engineering Automation LLP and is set to further his skills in Tecnomatix Process Simulate software by Siemens, aiming to excel in digital manufacturing and automation."
  },
  {
    name: "Avani Thakur",
    role: "Designer, Social Media, Management",
    avtar: "https://media.licdn.com/dms/image/v2/D4D03AQGe2Zhr-jd2TA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1697733975810?e=1732752000&v=beta&t=XnTMHi_O4PVQUg5hsDUXPxFqoBJsdz1w0h2_4FUMnBc",
    bio: "Avani is a designer and engineer passionate about digital circuit design and project development. As a core team member at Taksh, she enhances engineering education through creative, cutting-edge projects. Skilled in Python and SQL, she develops data-driven solutions. she also contributes to TEDx and is currently expanding his expertise during a one-year internship at DIAT, focusing on digital circuit design and data analysis. His goal is to create impactful solutions in data science and engineering."
  },

];

export default function AboutPage() {
  const [selectedMember, setSelectedMember] = useState(null);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const pageName = "AboutPage";
  const projectTitle = "About Taksh";
  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  const highlightTaksh = (text) => {
    return text.split(/(Taksh)/).map((part, index) =>
      part === 'Taksh' ? <span key={index} className="font-display text-[#FEF158]">{part}</span> : part
    );
  };

  return (
    <main className="bg-gradient-to-b from-[#232320] via-[#2C3E50] to-[#232320] text-[#E0E0E0] min-h-screen py-10 content-container">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* About heading */}
        <section className="text-center border-b border-gray-700 pb-12 mb-16">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 animate-fade-in font-display">
            {highlightTaksh("Game × Project-Based-Learning = Taksh")}
          </h1>
          <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto">
            Transforming education through gamification and real-world projects
          </p>
        </section>

        {/* About history */}
        <section className="mb-24">
          <div className="grid md:grid-cols-2 gap-12">
            <div className="space-y-6">
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                {highlightTaksh("Taksh is a child's dream come true. Education turned into a game. It is a Innovative ed-tech platform designed to transform disengaged-unwilling youth into skilled and inspired individuals. It seamlessly integrates gamification with project-based learning, fostering a community-driven approach to skill development.")}
              </p>
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                {highlightTaksh("The Taksh platform comprises a dual structure: a Community and a Game-as-a-Service (SaaS) component. It empowers users to collaborate on projects while enjoying the engaging elements of a game.")}
              </p>
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                The game itself is structured as a metric-based system with two primary progression paths: a bottom-up approach involving gradual level advancement and a sideways approach encouraging exploration of diverse domains. As players build projects and ascend through the seven levels, they cultivate a range of super skills, including communication, presentation, leadership, and project management and more. Simultaneously, focused project development in specific areas leads to the acquisition of hard skills.
              </p>
            </div>
            <div className="space-y-6">
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                {highlightTaksh("Recognizing the potential of already skilled individuals, Taksh incorporates a mechanism to accelerate their progress. Through a validation process, these skilled users can directly contribute to the community by sharing their projects and are granted direct access to higher levels, leveraging their expertise from the outset.")}
              </p>
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                {highlightTaksh("Central to Taksh's philosophy is the concept of reciprocal learning. The platform promotes a volunteer-based community where members contribute their knowledge and experience while benefiting from the collective wisdom of others. This symbiotic relationship encourages a culture of shared growth and development.")}
              </p>
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                {highlightTaksh("In essence, Taksh offers a dynamic and inclusive environment where individuals can learn, collaborate, and thrive. By blending the excitement of gaming with the practical application of project-based learning, Taksh empowers youth to unlock their full potential and become valuable contributors to society.")}
              </p>
            </div>
          </div>
        </section>

        {/* Mission statement */}
        <section className="bg-blue-900 rounded-lg p-8 mb-24 shadow-lg transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center font-display">Our Mission</h2>
          <p className="text-xl md:text-2xl text-center">
            Nurture Young People to Make a Shift From Fake Resume to Unique & Trustworthy Porfolio of Projects
          </p>
        </section>

        {/* People */}
        <section>
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 font-display">Our Team</h2>

          {/* CEO */}
          <div className="grid md:grid-cols-3 gap-12 mb-24">
            <div className="text-center">
              <img
                src="https://media.licdn.com/dms/image/v2/D4D03AQH44xb_J2O3gQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1704692906829?e=1732752000&v=beta&t=BsaP2e0pvEbHeNAUzf6Cl8g3rIaU05bN6pnhkMx4Pok"
                alt="Malhar Kulkarni"
                className="rounded-full mx-auto mb-6 w-48 h-48 object-cover border-4 border-blue-500 shadow-lg"
              />
              <h3 className="text-2xl font-semibold mb-2 font-display">Malhar Kulkarni</h3>
              <p className="text-blue-400 mb-4">Co-Founder, Developer, Community Manager</p>
              <div className="flex justify-center space-x-4">
                <a href="/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue-400 transition-colors">
                  <Globe size={24} />
                </a>
                <a href="https://www.linkedin.com/in/coolmalhar/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue-400 transition-colors">
                  <Linkedin size={24} />
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue-400 transition-colors">
                  <Twitter size={24} />
                </a>
              </div>
            </div>
            <div className="md:col-span-2 space-y-4">
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                Malhar, a curious and visionary seeker, founded {highlightTaksh("Taksh")} with the mission to revolutionize engineering education in India. His goal is to transform the current system that often produces underprepared graduates into one that nurtures skilled, inspired individuals through project-based learning.
              </p>
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                Passionate about everything tech, he aims to deepen his clarity about the mechanics of the world. He says, "I am fortunate that i was nurtured by many mentors. These people were passionate about employing tech wherever they can and were compassionate about how to bring well-being in people's lives, using everything they know best. That has shaped me as a person and this is just an expression of all those helping hands."
              </p>
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">
                As a dedicated seeker of knowledge and growth, Malhar is also a practitioner of yoga, embodying the balance between technological innovation and holistic well-being.
              </p>
            </div>
          </div>

          {/* Team members */}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
            {teamMembers.map((member, index) => (
              <div key={index} className="text-center bg-gray-800 rounded-lg p-6 shadow-lg transform hover:scale-105 transition-all duration-300">
                <img
                  src={member.avtar}
                  alt={member.name}
                  className="rounded-full mx-auto mb-6 w-32 h-32 object-cover border-2 border-blue-400"
                />
                <h3 className="text-lg md:text-xl font-semibold mb-2 font-display">{member.name}</h3>
                <p className="text-sm md:text-base text-blue-400 mb-4">{member.role}</p>
                <button
                  onClick={() => setSelectedMember(member)}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center transition-colors duration-200"
                >
                  <User className="mr-2" size={16} />
                  View Bio
                </button>
              </div>
            ))}
          </div>
        </section>
      </div>
      <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal} pageName={pageName} projecttitle={projectTitle} />
      <FloatingButton onClick={openFeedbackModal} isFeedbackModalOpen={isFeedbackModalOpen} />
      <Modal
        isOpen={!!selectedMember}
        onClose={() => setSelectedMember(null)}
      >
        {selectedMember && (
          <div className="p-6">
            <h3 className="text-2xl font-bold mb-2 font-display">{selectedMember.name}</h3>
            <p className="text-blue-400 mb-4">{selectedMember.role}</p>
            <p className="text-gray-300 leading-relaxed">{selectedMember.bio}</p>
          </div>
        )}
      </Modal>
    </main>
  );
}