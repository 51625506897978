import React, { useState } from 'react';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { AiFillFire, AiFillEdit } from 'react-icons/ai';
import { FaChevronRight, FaGithub } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import FeedbackModal from '../components/molecules/FeedbackModal';
import FloatingButton from '../components/molecules/FloatingButton';
import { useSelector } from 'react-redux';

const LEVEL_COLORS = {
  'Level 1': '#4CAF50',
  'Level 2': '#8BC34A',
  'Level 3': '#CDDC39',
  'Level 4': '#FFEB3B',
  'Level 5': '#FFC107',
  'Level 6': '#FF9800',
  'Level 7': '#FF5722'
};

const LEVEL_DATA = [
  { name: 'Level 1', projects: 5 },
  { name: 'Level 2', projects: 8 },
  { name: 'Level 3', projects: 12 },
  { name: 'Level 4', projects: 15 },
  { name: 'Level 5', projects: 10 },
  { name: 'Level 6', projects: 7 },
  { name: 'Level 7', projects: 3 }
];

const BADGES = [
  { name: 'Level 1 Badge', image: '/placeholder.svg?height=128&width=128&text=L1' },
  { name: 'Level 2 Badge', image: '/placeholder.svg?height=128&width=128&text=L2' },
  { name: 'Level 3 Badge', image: '/placeholder.svg?height=128&width=128&text=L3' },
];

const STREAK_DATA = Array.from({ length: 365 }, (_, i) => ({
  date: new Date(2023, 0, i + 1),
  value: Math.random() > 0.7 ? Math.floor(Math.random() * 4) + 1 : 0,
}));

export default function ProfilePage() {
  const user = useSelector((state) => state.auth.user);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [xp, setXp] = useState(12345);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const pageName = "ProfilePage";
  const projecttitle = "Profile";
  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#232320] via-[#2C3E50] to-[#232320] text-[#E0E0E0] font-normalText">
      <div className="container mx-auto px-4 py-8">
        <div className="bg-[#2C3E50] shadow-lg rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
                <img
                  src={user.profilePicture || "https://img.playbook.com/KL50jPCjNz1Y8faMyEG1r-kGm5Fz8Wz0O32LBlNZObY/Z3M6Ly9wbGF5Ym9v/ay1hc3NldHMtcHVi/bGljLzA3OGQ1OTk4/LTg3YWEtNDIzMS05/YmMxLTYxZWU0MDA4/NjlkMg"}
                  alt="Profile picture"
                  className="w-32 h-32 rounded-full border-4 border-[#FEF158] shadow-lg mb-4 md:mb-0 md:mr-6"
                />
                <div className="text-center md:text-left">
                  <h1 className="text-3xl font-bold font-display text-[#FEF158]">{user.username}</h1>
                  <p className="text-sm text-[#E0E0E0]">{user.email}</p>
                  <p className="text-xs text-[#A0AEC0] mt-2">Profile customization coming soon!</p>
                </div>
              </div>
              <div className="flex flex-col items-center space-y-4">
                <div className="text-3xl font-bold text-[#FEF158] font-display">XP</div>
                <div className="relative">
                  <svg className="w-24 h-24" viewBox="0 0 100 100">
                    <defs>
                      <linearGradient id="xpGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" stopColor="#FEF158" />
                        <stop offset="100%" stopColor="#00BCD4" />
                      </linearGradient>
                    </defs>
                    <polygon points="50,5 95,30 95,70 50,95 5,70 5,30" fill="url(#xpGradient)" />
                  </svg>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-2xl font-bold text-[#232320]">{xp.toLocaleString()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-[#3E4C5E] px-4 py-5 sm:px-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h2 className="text-lg font-semibold mb-2 font-display text-[#FEF158] flex items-center">
                  About <AiFillEdit className="ml-2 cursor-pointer text-[#A0AEC0]" title="Edit coming soon" />
                </h2>
                <p className="text-sm text-[#E0E0E0]">
                  This is a placeholder bio. Soon, you'll be able to customize this section to showcase your developer journey!
                </p>
              </div>
              <div>
                <h2 className="text-lg font-semibold mb-2 font-display text-[#FEF158] flex items-center">
                  Skills <AiFillEdit className="ml-2 cursor-pointer text-[#A0AEC0]" title="Edit coming soon" />
                </h2>
                <div className="flex flex-wrap gap-2">
                  {['JavaScript', 'React', 'Node.js', 'Python', 'SQL'].map((skill) => (
                    <span
                      key={skill}
                      className="px-2 py-1 text-xs rounded-full bg-[#3E4C5E] text-[#E0E0E0]"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
                <p className="text-xs text-[#A0AEC0] mt-2">These are example skills. You'll be able to add your own soon!</p>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6 bg-[#3E4C5E]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="bg-[#2C3E50] p-4 rounded-lg">
                <h2 className="text-lg font-semibold mb-4 font-display text-[#FEF158]">Level Progress</h2>
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={LEVEL_DATA}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#4A5568" />
                      <XAxis dataKey="name" stroke="#E0E0E0" />
                      <YAxis stroke="#E0E0E0" />
                      <Tooltip contentStyle={{ backgroundColor: '#2C3E50', border: 'none' }} />
                      <Bar dataKey="projects" fill="#8884d8">
                        {LEVEL_DATA.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={LEVEL_COLORS[entry.name]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="mt-4 text-sm text-[#E0E0E0]">
                  Projects completed per level
                </div>
              </div>
              <div className="bg-[#2C3E50] p-4 rounded-lg">
                <h2 className="text-lg font-semibold mb-4 font-display text-[#FEF158]">Rewards</h2>
                <div className="flex items-start justify-between mb-4">
                  <div>
                    <div className="text-xs text-[#E0E0E0]">Badges</div>
                    <div className="mt-1.5 text-2xl leading-[18px] text-[#FEF158]">{BADGES.length}</div>
                  </div>
                  <FaChevronRight className="h-6 w-6 text-[#E0E0E0] cursor-pointer" />
                </div>
                <div className="flex items-center justify-center">
                  {BADGES.map((badge, index) => (
                    <div key={badge.name} className={`${index === BADGES.length - 1 ? '' : 'mr-[28px]'} h-24 w-24`}>
                      <img src={badge.image} alt={badge.name} className="h-full w-full cursor-pointer object-contain" />
                    </div>
                  ))}
                </div>
                <div className="mt-4">
                  <div className="text-xs text-[#E0E0E0]">Most Recent Badge</div>
                  <div className="text-base text-[#FEF158]">{BADGES[BADGES.length - 1].name}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6 bg-[#2C3E50]">
            <h2 className="text-lg font-semibold mb-4 font-display text-[#FEF158]">Recent Activity</h2>
            <div className="space-y-4">
              {[1, 2, 3].map((item) => (
                <div
                  key={item}
                  className="flex items-center p-3 rounded-lg bg-[#3E4C5E] shadow"
                >
                  <AiFillFire className="text-2xl text-[#FEF158] mr-3" />
                  <div>
                    <h3 className="font-semibold text-[#E0E0E0]">Completed Project {item}</h3>
                    <p className="text-sm text-[#A0AEC0]">
                      2 days ago
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6 bg-[#3E4C5E]">
            <h2 className="text-lg font-semibold mb-4 font-display text-[#FEF158]">Login Streak</h2>
            <div className="overflow-x-auto">
              <div className="inline-grid grid-cols-[repeat(53,_1fr)] gap-1">
                {STREAK_DATA.map((day, index) => (
                  <div
                    key={index}
                    className={`w-3 h-3 rounded-sm ${day.value === 0 ? 'bg-[#2C3E50]' :
                        day.value === 1 ? 'bg-[#FEF15866]' :
                          day.value === 2 ? 'bg-[#FEF15899]' :
                            day.value === 3 ? 'bg-[#FEF158CC]' :
                              'bg-[#FEF158]'
                      }`}
                    title={`${day.date.toDateString()}: ${day.value} contributions`}
                  />
                ))}
              </div>
            </div>
            <p className="text-xs text-[#A0AEC0] mt-2">This is a sample login streak. Your actual streak will be tracked in future updates!</p>
          </div>
        </div>
      </div>
      <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal} pageName={pageName} projecttitle={projecttitle} />
      <FloatingButton onClick={openFeedbackModal} isFeedbackModalOpen={isFeedbackModalOpen} />
    </div>
  );
}