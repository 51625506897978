import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../../utils/api';
import { motion } from 'framer-motion';
import GoogleLoginButton from './googleButton';

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password1: '',
    password2: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (formData.password1 !== formData.password2) {
      setError("Passwords don't match");
      return;
    }

    try {
      const response = await signUp(formData);
      if (response.status === 204) {  
        setSuccess(true);
        // Clear the form
        setFormData({
          username: '',
          email: '',
          password1: '',
          password2: '',
        });
        // Set a timeout to redirect after showing the success message
        setTimeout(() => {
          navigate('/map', { replace: true });
        }, 2000); // Redirect after 2 seconds
      }
    } catch (err) {
      if (err.response && err.response.data) {
        const errorMessages = Object.values(err.response.data).flat();
        setError(errorMessages.join(' '));
      } else {
        setError('An error occurred during signup');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#232320] via-[#2C3E50] to-[#232320]">
      <motion.form
        onSubmit={handleSubmit}
        className="bg-[#2C3E50] p-8 rounded-lg shadow-xl w-full max-w-md"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl font-display mb-6 text-center text-[#FEF158]">Sign Up</h2>
        {error && (
          <motion.p
            className="text-red-500 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {error}
          </motion.p>
        )}
        {success && (
          <motion.p
            className="text-green-500 mb-4 text-center font-bold"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            Sign up successful! Redirecting to login page...
          </motion.p>
        )}
        <div className="mb-4">
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Username"
            className="w-full p-2 rounded bg-[#34495E] text-[#E0E0E0] focus:outline-none focus:ring-2 focus:ring-[#FEF158]"
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-full p-2 rounded bg-[#34495E] text-[#E0E0E0] focus:outline-none focus:ring-2 focus:ring-[#FEF158]"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            name="password1"
            value={formData.password1}
            onChange={handleChange}
            placeholder="Password"
            className="w-full p-2 rounded bg-[#34495E] text-[#E0E0E0] focus:outline-none focus:ring-2 focus:ring-[#FEF158]"
            required
          />
        </div>
        <div className="mb-6">
          <input
            type="password"
            name="password2"
            value={formData.password2}
            onChange={handleChange}
            placeholder="Confirm Password"
            className="w-full p-2 rounded bg-[#34495E] text-[#E0E0E0] focus:outline-none focus:ring-2 focus:ring-[#FEF158]"
            required
          />
        </div>
        <motion.button
          type="submit"
          className="w-full bg-[#FEF158] text-[#2C3E50] py-2 rounded font-bold hover:bg-[#FEF158CC] transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Sign Up
        </motion.button>
      {/* ... other signup form elements */}
      {/*<GoogleLoginButton />*/}
      </motion.form>

    </div>
  );
};

export default SignUp;