import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { login, getUserProfile } from '../../utils/api';
import { setUser, setToken } from '../../store/slices/authSlice';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await login(formData);
      if (response.data.access) {
        localStorage.setItem('access_token', response.data.access);
        dispatch(setToken(response.data.access));
        const userResponse = await getUserProfile(response.data.access);
        dispatch(setUser(userResponse.data));
        setSuccess('Login successful! Redirecting...');
        setTimeout(() => {
          navigate('/map', { replace: true });
        }, 2000);
      }
    } catch (err) {
      console.error('Login error:', err.response?.data);
      setError(err.response?.data?.detail || 'An error occurred during login');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#232320] via-[#2C3E50] to-[#232320]">
      <motion.form
        onSubmit={handleSubmit}
        className="bg-[#2C3E50] p-8 rounded-lg shadow-xl w-full max-w-md"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl font-display mb-6 text-center text-[#FEF158]">Login</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}
        <div className="mb-4">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-full p-2 rounded bg-[#34495E] text-[#E0E0E0] focus:outline-none focus:ring-2 focus:ring-[#FEF158]"
            required
          />
        </div>
        <div className="mb-6">
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            className="w-full p-2 rounded bg-[#34495E] text-[#E0E0E0] focus:outline-none focus:ring-2 focus:ring-[#FEF158]"
            required
          />
        </div>
        <motion.button
          type="submit"
          className="w-full bg-[#FEF158] text-[#2C3E50] py-2 rounded font-bold hover:bg-[#FEF158CC] transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Login
        </motion.button>
      </motion.form>
    </div>
  );
};

export default Login;