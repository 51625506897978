import React, { useEffect, useState } from 'react';
import ProjectCard from '../components/molecules/ProjectCard';
import { motion, AnimatePresence } from 'framer-motion';
import { getLevel } from '../utils/api';
import { useLocation } from 'react-router-dom';
import FeedbackModal from '../components/molecules/FeedbackModal';
import FloatingButton from '../components/molecules/FloatingButton';

const formatTime = (time) => {
  if (time <= 24) {
    return `${time} hrs`;
  } else if (time <= 36) {
    return `1 Day`;
  } else {
    const days = Math.round(time / 24);
    return `${days} Days`;
  }
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      duration: 0.5,
    },
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 },
  },
};

const ProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const { levelId } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const pageName = "ProjectDisplay";
  const projecttitle = "Projects";
  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  const fetchProjects = async (url) => {
    if (!levelId && !url) {
      console.error('levelId is undefined and no URL provided');
      setError('Level ID is missing and no URL provided.');
      setLoading(false);
      return;
    }

    try {
      console.log(`Fetching projects for level: ${levelId || 'from URL'}`);
      const response = await (url ? getLevel(url) : getLevel(levelId));
      console.log('API Response:', response);

      if (response.data && Array.isArray(response.data.results)) {
        console.log('Projects data:', response.data.results);
        setProjects(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setTotalCount(response.data.count);
      } else {
        console.error('Unexpected data structure:', response.data);
        setError('Received unexpected data structure from the API.');
      }
    } catch (err) {
      console.error('Error fetching projects:', err);
      setError(`Failed to fetch projects: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [levelId]);

  const handleNextPage = () => {
    if (nextPage) {
      fetchProjects(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (prevPage) {
      fetchProjects(prevPage);
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#232320] via-[#2C3E50] to-[#232320] text-[#E0E0E0] min-h-screen py-10 content-container">
      <div className="container mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-6xl md:text-7xl font-display mb-6 text-[#FEF158]">
            {`Level ${levelId || 'Projects'}`}
          </h1>
          <h3 className="text-2xl md:text-3xl text-[#E0E0E0] font-normalText">
            No more theory, time to build something dope.
          </h3>
        </div>
        {loading ? (
          <p className="text-center text-xl">Loading...</p>
        ) : error ? (
          <p className="text-center text-xl text-red-500">{error}</p>
        ) : (
          <>
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-16"
            >
              <AnimatePresence>
                {projects.map((project) => (
                  <motion.div
                    key={project.id}
                    variants={cardVariants}
                  >
                    <ProjectCard project={{ ...project, time: formatTime(project.time) }} />
                  </motion.div>
                ))}
              </AnimatePresence>
            </motion.div>
            
            <div className="flex flex-col sm:flex-row justify-between items-center mt-12 space-y-4 sm:space-y-0">
              <button 
                onClick={handlePrevPage} 
                disabled={!prevPage}
                className="w-full sm:w-auto px-6 py-2 text-sm font-medium text-[#FEF158] bg-[#2C3E50] rounded-md shadow-md hover:bg-[#34495E] focus:outline-none focus:ring-2 focus:ring-[#FEF158] disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Previous
              </button>
              <span className="text-[#E0E0E0] text-sm">{`Showing ${projects.length} of ${totalCount} projects`}</span>
              <button 
                onClick={handleNextPage} 
                disabled={!nextPage}
                className="w-full sm:w-auto px-6 py-2 text-sm font-medium text-[#FEF158] bg-[#2C3E50] rounded-md shadow-md hover:bg-[#34495E] focus:outline-none focus:ring-2 focus:ring-[#FEF158] disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
      <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal} pageName={pageName} projecttitle={projecttitle} />
      <FloatingButton onClick={openFeedbackModal} isFeedbackModalOpen={isFeedbackModalOpen} />
    </div>
  );
};

export default ProjectsPage;