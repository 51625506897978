import React from 'react';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

const GoogleLoginButton = () => {
    const handleGoogleLogin = () => {
        const clientID = "558891694003-3tn1cv3sq7r7btr6227l9a5fbbm2mcgm.apps.googleusercontent.com";
        const callBackURI = "https://gameoftaksh.live";
        const scope = "openid%20email%20profile";
        const googleAuthURL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${encodeURIComponent(callBackURI)}&prompt=consent&response_type=code&client_id=${clientID}&scope=${scope}&access_type=offline`;
        
        window.location.href = googleAuthURL;
    };

    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<GoogleIcon />}
            onClick={handleGoogleLogin}
            fullWidth
            style={{ marginTop: '1rem' }}
        >
            Sign in with Google
        </Button>
    );
};

export default GoogleLoginButton;
