import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const GoogleAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      // Store the token in localStorage or your state management solution
      localStorage.setItem('authToken', token);
      // Redirect to the desired page after successful login
      navigate('/');
    } else {
      // Handle error case
      navigate('/login');
    }
  }, [location, navigate]);

  return (<div>Processing Google login...</div>);
};

