import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from './components/molecules/Navbar';
import Footer from './components/molecules/Footer';
import LandingPage from './pages/LandingPage';
import ProjectsPage from './pages/ProjectsPage';
import MapPage from './pages/MapPage';
import ProjectDetailsPage from './pages/ProjectDetailsPage';
import AboutPage from './pages/AboutPage';
import ProfilePage from './pages/ProfilePage';
import SignUp from './components/molecules/SignUp';
import Login from './components/molecules/Login';
import PrivateRoute from './components/molecules/PrivateRoute';
import { setUser, setToken, logout } from './store/slices/authSlice';
import { getUserProfile } from './utils/api';
import { GoogleAuthCallback } from './components/molecules/googleCallBack';
const AppContent = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(setToken(token));
      // Fetch user data using the token
      getUserProfile(token)
        .then(response => {
          dispatch(setUser(response.data));
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          // If there's an error (e.g., token expired), log out the user
          dispatch(logout());
        });
    }
  }, [dispatch]);

  useEffect(() => {
    console.log('Current user:', user);
  }, [user]);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/level/:lvl/project/:id" element={<ProjectDetailsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/profile" element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          } />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/api/google" element={<GoogleAuthCallback />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default AppContent;
