import React, { useState, useRef } from 'react';
import { motion, useScroll, useTransform, useSpring, useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import FeedbackModal from '../components/molecules/FeedbackModal';
import FloatingButton from '../components/molecules/FloatingButton';
//import MailchimpModal from '../components/molecules/MailchimpModal';

const Section = ({ children, id, className = "" }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.3 });

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className={`min-h-screen flex flex-col items-center justify-center p-4 sm:p-8 md:p-12 lg:p-20 ${className}`}
      id={id}
    >
      {children}
    </motion.section>
  );
};

const ParallaxText = ({ children, y = [0, -50] }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"]
  });
  const yValue = useTransform(scrollYProgress, [0, 1], y);

  return (
    <motion.div ref={ref} style={{ y: yValue }}>
      {children}
    </motion.div>
  );
};

const GradientButton = ({ children, href, onClick }) => (
  <div className="relative inline-block">
    <motion.span
      className="absolute w-0 h-0 border-l-[70px] border-r-[70px] border-b-[120px] border-l-transparent border-r-transparent border-b-white -mt-2 -ml-36 opacity-20 pointer-events-none"
      initial={{ translateX: -60, translateY: -20 }}
      animate={{ translateX: -64, translateY: 64 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    />
    <motion.a
      href={href}
      onClick={onClick}
      className="relative inline-flex items-center justify-center px-6 sm:px-8 md:px-10 py-3 sm:py-4 font-display text-sm sm:text-base md:text-lg text-[#232320] rounded-full bg-gradient-to-r from-[#FEF158] to-[#00BCD4] shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl"
      whileHover={{ scale: 1.07 }}
      whileTap={{ scale: 0.95 }}
    >
      <span className="relative z-10 text-2xl">{children}</span>
    </motion.a>
  </div>
);

export default function LandingPage() {
  console.log(`
 ______   ______     __  __     ______     __  __    
/\\__  _\\ /\\  __ \\   /\\ \\/ /    /\\  ___\\   /\\ \\_\\ \\   
\\/_/\\ \\/ \\ \\  __ \\  \\ \\  _"-.  \\ \\___  \\  \\ \\  __ \\  
   \\ \\_\\  \\ \\_\\ \\_\\  \\ \\_\\ \\_\\  \\/\\_____\\  \\ \\_\\ \\_\\ 
    \\/_/   \\/_/\\/_/   \\/_/\\/_/   \\/_____/   \\/_/\\/_/ 
                                                      
  `);

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  //const [isMailchimpModalOpen, setIsMailchimpModalOpen] = useState(false);
  const navigate = useNavigate();

  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  //const openMailchimpModal = () => setIsMailchimpModalOpen(true);
  //const closeMailchimpModal = () => setIsMailchimpModalOpen(false);

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  //const handleSuccessfulSignup = () => {
  //  closeMailchimpModal();
  //  navigate('/map');
  //};

  const handleNavigateToMap = () => {
    navigate('/map', { state: { scrollToCenter: true } });
  };

  return (
    <div className="bg-gradient-to-b from-[#232320] via-[#2C3E50] to-[#232320] text-[#E0E0E0] font-sans content-container">
      {/* Scroll Progress Bar */}
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-[#FEF158] to-[#00BCD4] origin-[0%] z-50"
        style={{ scaleX }}
      />

      {/* Main Section */}
      <Section id="main" className="text-center">
        <ParallaxText y={[0, -100]}>
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-display mb-4 sm:mb-6 text-[#FEF158]">
            Get Skilled with Real Projects
          </h1>
        </ParallaxText>
        <ParallaxText y={[0, -50]}>
          <p className="text-xl sm:text-2xl md:text-3xl text-[#E0E0E0] mb-8 sm:mb-12 font-normalText">
            Enough Bull S***, Gamify your Growth with <span className="font-display text-[#FEF158] decoration-[#FEF158]">Taksh</span>
          </p>
        </ParallaxText>
        <GradientButton href="#truth">What now?</GradientButton>
      </Section>

      {/* Truth Section */}
      <Section id="truth">
        <ParallaxText>
          <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-display mb-6 sm:mb-8 text-center text-[#FEF158]">
            Hard Truth
          </h2>
        </ParallaxText>
        <p className="text-lg sm:text-xl md:text-2xl text-[#E0E0E0] max-w-3xl mb-8 sm:mb-12 text-center leading-relaxed font-normalText">
          Degree is Dead!<br className="hidden sm:inline" /> The Proof of Competence was a Degree / Certification.<br className="hidden sm:inline" /> Not Anymore.
        </p>
        <GradientButton href="#problem">ik 😭</GradientButton>
      </Section>

      {/* Problem Section */}
      <Section id="problem">
        <ParallaxText>
          <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-display mb-6 sm:mb-8 text-center text-[#FEF158]">
            Problem
          </h2>
        </ParallaxText>
        <p className="text-lg sm:text-xl md:text-2xl text-[#E0E0E0] max-w-3xl mb-8 sm:mb-12 text-center leading-relaxed font-normalText">
          Too Much Theory No Real Experience. <br className="hidden sm:inline" />
          How will you know and let others know <br className="hidden sm:inline" />
          that you are capable enough for something? <br className="hidden sm:inline" />What is the proof of your competence now?
        </p>
        <GradientButton href="#solution">Solution</GradientButton>
      </Section>

      {/* Solution Section */}
      <Section id="solution">
        <ParallaxText>
          <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-display mb-6 sm:mb-8 text-center text-[#FEF158]">
            Solution
          </h2>
        </ParallaxText>
        <p className="text-lg sm:text-xl md:text-2xl text-[#E0E0E0] max-w-3xl mb-8 sm:mb-12 text-center leading-relaxed font-normalText">
          Turn everything you learn into a game or challenge, <br className="hidden sm:inline" />and you'll keep your "curiosity" and "passion" alive. <br className="hidden sm:inline" /> Two fundamental things you are missing.<br className="hidden sm:inline" /> Make your learning into an adventure!
        </p>
        <GradientButton href="#gamify">How?</GradientButton>
      </Section>

      {/* Gamify Section */}
      <Section id="gamify">
        <ParallaxText>
          <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-display mb-6 sm:mb-8 text-center text-[#FEF158]">
            Gamify
          </h2>
        </ParallaxText>
        <p className="font-normalText text-lg sm:text-xl md:text-2xl text-[#E0E0E0] max-w-3xl mb-8 sm:mb-12 text-center leading-relaxed">
          You need to be building stuff with all the tech out there, <br className="hidden sm:inline" />while having fun. This is the playground for that! Even better <br className="hidden sm:inline" />when there's a community of people ready to break their limits. <br className="hidden sm:inline" />You need to level up. <br className="hidden sm:inline" />You need to play the <span className="font-display text-[#FEF158] font-bold decoration-[#FEF158]">Game of Taksh</span> ...
        </p>
        <GradientButton onClick={handleNavigateToMap}>
          Shall we?
        </GradientButton>
      </Section>
      <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal}  />
      <FloatingButton onClick={openFeedbackModal} isFeedbackModalOpen={isFeedbackModalOpen} />
      {/*  <MailchimpModal isOpen={isMailchimpModalOpen} onClose={closeMailchimpModal} onSuccessfulSignup={handleSuccessfulSignup} /> */}
    </div>
  );
}