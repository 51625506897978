import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { FaUserCircle, FaClock, FaDiscord, FaTree, FaCheckCircle, FaBolt, FaExternalLinkAlt, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getProjects, getLevel } from '../utils/api';
import FeedbackModal from '../components/molecules/FeedbackModal';
import FloatingFeedbackButton from '../components/molecules/FloatingButton';

const Breadcrumbs = ({ level, title }) => {
  const navigate = useNavigate();

  return (
    <nav className="text-sm mb-4 overflow-x-auto whitespace-nowrap">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-[#00BCD4] hover:text-[#FEF158]">Home</Link>
          <svg className="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
        </li>
        <li className="flex items-center">
          <button 
            onClick={() => navigate('/projects', { state: { levelId: level } })}
            className="text-[#00BCD4] hover:text-[#FEF158]"
          >
            Level {level}
          </button>
          <svg className="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
        </li>
        <li>
          <span className="text-[#E0E0E0]">{title}</span>
        </li>
      </ol>
    </nav>
  );
};

const RelatedProjects = ({ projects }) => (
  <div className="mt-10">
    <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 text-[#FEF158]">Related Projects</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {projects.map((project) => (
        <Link 
          key={project.id} 
          to={`/level/${project.level_id}/project/${project.id}`} 
          className="block p-4 bg-[#2C3E50] rounded-lg hover:shadow-lg transition-shadow"
        >
          <h3 className="font-bold text-[#FEF158] mb-2">{project.title}</h3>
          <p className="text-sm text-[#E0E0E0]">{project.one_liner}</p>
        </Link>
      ))}
    </div>
  </div>
);

const ProjectDetailsPage = () => {
  const navigate = useNavigate();
  const { lvl, id } = useParams();
  const [project, setProject] = useState(null);
  const [showMCQ, setShowMCQ] = useState(false);
  const [userAnswers, setUserAnswers] = useState({});
  const [score, setScore] = useState(null);
  const [showMilestones, setShowMilestones] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await getProjects(lvl, id);
        setProject(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProject();
  }, [lvl, id]);

  if (!project) {
    return <p>Loading project details...</p>;
  }
  const pageName = "ProjectDetails";
  const projecttitle = project.title;
  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  const formatTime = (time) => {
    return `${time} hrs`;
  };

 const handleAnswerSelect = (questionIndex, optionIndex) => {
    setUserAnswers({ ...userAnswers, [questionIndex]: optionIndex });
  };

  const handleSubmit = () => {
    let correctAnswers = 0;
    Object.keys(project.mcq).filter(key => key.startsWith('answer_')).forEach((key, index) => {
      if (userAnswers[index] === project.mcq[key]) { 
        correctAnswers++;
      }
    });
    setScore(correctAnswers);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, staggerChildren: 0.1 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const GradientButton = ({ children, href, onClick, className }) => (
    <motion.a
      href={href}
      onClick={onClick}
      className={`relative inline-flex items-center justify-center px-6 py-3 font-display text-sm md:text-base text-[#232320] rounded-md bg-gradient-to-r from-[#FEF158] to-[#00BCD4] shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl ${className}`}
      whileHover={{ scale: 1.07 }}
      whileTap={{ scale: 0.95 }}
    >
      <span className="relative z-10">{children}</span>
    </motion.a>
  );

  const GlowingButton = ({ children, onClick, className }) => (
    <motion.button
      onClick={onClick}
      className={`relative overflow-hidden px-8 py-3 rounded-full font-bold text-white ${className}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <span className="absolute inset-0 bg-gradient-to-r from-orange-400 to-red-500"></span>
      <span className="absolute inset-0 bg-gradient-to-r from-orange-500 to-red-600 opacity-0 hover:opacity-100 transition-opacity duration-300"></span>
      <span className="relative z-10">{children}</span>
      <span className="absolute inset-0 bg-gradient-to-r from-yellow-400 to-red-500 blur-md opacity-50 animate-pulse"></span>
    </motion.button>
  );

  const NeonButton = ({ children, onClick, className }) => (
    <motion.button
      onClick={onClick}
      className={`relative px-8 py-3 rounded-lg font-bold text-white ${className}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-yellow-500 opacity-75"></span>
      <span className="absolute inset-0 bg-black opacity-75 hover:opacity-0 transition-opacity duration-300"></span>
      <span className="relative z-10">{children}</span>
      <span className="absolute inset-0 border-2 border-white rounded-lg"></span>
      <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-yellow-500 blur-md opacity-50 animate-pulse"></span>
    </motion.button>
  );

  const FloatingButton = ({ children, href, className }) => (
    <motion.a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`inline-flex items-center px-6 py-3 rounded-lg font-bold ${className}`}
      whileHover={{ y: -5, boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)" }}
      whileTap={{ y: 0, boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)" }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
      {children}
    </motion.a>
  );

    const renderMilestoneTree = (milestones) => {
    return (
      <div className="milestone-tree relative">
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-[#FEF158] to-[#00BCD4]"></div>
        {milestones.map((milestone, index) => (
          <motion.div
            key={milestone.id}
            className="milestone-node relative mb-12"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className={`flex flex-col md:flex-row items-center ${index % 2 === 0 ? 'md:flex-row-reverse' : ''}`}>
              <div className="w-full md:w-1/2"></div>
              <div className="milestone-icon w-12 h-12 absolute left-1/2 transform -translate-x-1/2 -translate-y-6 flex items-center justify-center">
                <div className="w-12 h-12 bg-[#FEF158] rounded-full flex items-center justify-center">
                  <p className="text-[#232320] text-2xl">🐜</p>
                </div>
              </div>
              <motion.div
                className={`w-full md:w-1/2 bg-[#2C3E50] p-6 rounded-lg shadow-xl ${
                  index % 2 === 0 ? 'md:mr-8 md:text-right' : 'md:ml-8'
                }`}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <h3 className="text-xl md:text-2xl font-bold mb-4 text-[#FEF158]">{milestone.task}</h3>
                {milestone.subtasks && milestone.subtasks.length > 0 && (
                  <ul className="space-y-2">
                    {milestone.subtasks.map((subtask) => (
                      <motion.li
                        key={subtask.id}
                        className="flex items-center text-[#E0E0E0] text-sm md:text-base"
                        initial={{ opacity: 0, x: index % 2 === 0 ? 20 : -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: 0.2 }}
                      >
                        <FaCheckCircle className="text-[#00BCD4] mr-2" />
                        <span>{subtask.task}</span>
                      </motion.li>
                    ))}
                  </ul>
                )}
              </motion.div>
            </div>
          </motion.div>
        ))}
      </div>
    );
  };

  return (
    <motion.div
      className="bg-gradient-to-b from-[#232320] via-[#2C3E50] to-[#232320] text-[#E0E0E0] font-sans min-h-screen py-10 content-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      
      <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal} pageName={pageName} projecttitle={projecttitle} />
      <FloatingFeedbackButton onClick={openFeedbackModal} isFeedbackModalOpen={isFeedbackModalOpen} />
      
      
      <div className="container mx-auto px-4">
        <Breadcrumbs level={lvl} title={project.title} />
        <motion.header className="text-center mb-10" variants={itemVariants}>
          <h1 className="text-3xl md:text-5xl lg:text-6xl font-display mb-2 text-[#FEF158]">{project.title}</h1>
          <p className="text-lg md:text-xl lg:text-2xl text-[#E0E0E0] font-normalText">{project.one_liner}</p>
        </motion.header>

        <motion.div className="max-w-3xl mx-auto mb-10" variants={itemVariants}>
          <img src={project.thumbnail_url} alt={project.title} className="rounded-lg w-full shadow-2xl" />
        </motion.div>

        <motion.div className="max-w-3xl mx-auto mb-10 bg-[#2C3E50] rounded-lg p-6 shadow-xl" variants={itemVariants}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center">
              <FaUserCircle size={24} className="text-[#FEF158] mr-2" />
              <div>
                <span className="text-base md:text-lg font-display">Contributor</span>
                <p className="text-sm md:text-md font-normalText">{project.contributor}</p>
              </div>
            </div>
            <div className="flex items-center">
              <FaClock size={24} className="text-[#FEF158] mr-2" />
              <span className="text-base md:text-lg font-normalText">{project.estimated_time} hrs</span>
            </div>
            <div>
              <span className="text-base md:text-lg font-display">📖 Skills to Learn</span>
              <p className="text-sm md:text-md font-normalText">{project.skills_to_learn.join(", ")}</p>
            </div>
            <div>
              <span className="text-base md:text-lg font-display">⩤ Domains</span>
              <p className="text-sm md:text-md font-normalText">{project.domains.join(", ")}</p>
            </div>
          </div>
        </motion.div>

        <motion.div className="max-w-3xl mx-auto mb-10" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 text-[#FEF158]">Tech Stack</h2>
          <div className="flex flex-wrap justify-center gap-4">
            {Object.entries(project.tech_stack).map(([tech, imageUrl]) => (
              <div key={tech} className="bg-[#2C3E50] rounded-lg p-4 text-center">
                <img src={imageUrl} alt={tech} className="w-12 h-12 md:w-16 md:h-16 mx-auto mb-2" />
                <p className="text-sm md:text-base font-normalText">{tech}</p>
              </div>
            ))}
          </div>
        </motion.div>

        <motion.div className="max-w-3xl mx-auto mb-10" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 text-[#FEF158]">Why this project? 🤔</h2>
          <p className="text-base md:text-lg lg:text-xl font-normalText">{project.relevance}</p>
        </motion.div>

        <motion.div className="max-w-3xl mx-auto mb-10" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 text-[#FEF158]">What are we building? 🔨</h2>
          <p className="text-base md:text-lg lg:text-xl font-normalText">{project.description}</p>
        </motion.div>

        <motion.div className="max-w-3xl mx-auto mb-10" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 text-[#FEF158]">Am I ready for this?</h2>
          <ul className="list-disc list-inside text-base md:text-lg lg:text-xl font-normalText">
            {project.prerequisites.map((prereq, index) => (
              <li key={index}>{prereq.trim()}</li>
            ))}
          </ul>
        </motion.div>

        <motion.div className="max-w-3xl mx-auto mb-10" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 text-[#FEF158]">Resources</h2>
          <ul className="list-disc list-inside text-base md:text-lg lg:text-xl font-normalText">
            {Object.entries(project.resources).map(([label, url], index) => (
              <li key={index}>
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-[#00BCD4] hover:text-[#FEF158]">
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </motion.div>

        <motion.div className="max-w-4xl mx-auto mb-16" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-8 text-center text-[#FEF158]">Walk with "Dhara"</h2>
          <GradientButton
            onClick={() => setShowMilestones(!showMilestones)}
            className="w-full mb-4"
          >
            {showMilestones ? (
              <>
                <FaChevronUp className="mr-2 inline" /> Hide Milestones
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2 inline" /> Show Milestones
              </>
            )}
          </GradientButton>
          {showMilestones && renderMilestoneTree(project.milestones)}
        </motion.div>

        <motion.div className="max-w-3xl mx-auto mb-10" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 text-[#FEF158]">Got stuck? Let's meet in Guild Hall</h2>
          <FloatingButton
            href={project.discord_forum_link}
            className="bg-[#5865F2] text-white hover:bg-[#4e58d4] w-full md:w-auto"
          >
            <FaDiscord className="mr-2" /> Join Forum
          </FloatingButton>
        </motion.div>

        <motion.div className="max-w-3xl mx-auto mb-10 flex flex-col md:flex-row items-center" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 md:mb-0 md:mr-5 text-[#FEF158]">Completed?</h2>
          <GlowingButton
            onClick={() => setShowMCQ(true)}
            className="text-base md:text-xl w-full md:w-auto"
          >
            <FaBolt className="mr-2 inline" />ShaZam
          </GlowingButton>
        </motion.div>

        <motion.div className="max-w-3xl mx-auto" variants={itemVariants}>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-display mb-4 text-[#FEF158]">Sample Solution</h2>
          <NeonButton
            onClick={() => window.open(project.sample_solution_link, '_blank')}
            className="text-base md:text-xl w-full md:w-auto"
          >
            <FaExternalLinkAlt className="mr-2 inline" /> View Solution
          </NeonButton>
        </motion.div>

        <RelatedProjects projects={project.related_projects} />
      </div>

      <AnimatePresence>
        {showMCQ && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100] p-4 overflow-y-auto"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-[#232320] p-6 rounded-lg shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto"
            >


          <h2 className="text-2xl md:text-3xl font-display mb-6 text-[#FEF158]">Quiz</h2>
              {Object.keys(project.mcq).filter(key => key.startsWith('question_')).map((key, index) => (
                <div key={index} className="mb-6">
                  <p className="text-base md:text-xl font-normalText mb-2">{project.mcq[key]}</p>
                  {project.mcq[`options_${index + 1}`].map((option, optionIndex) => (
                    <label key={optionIndex} className="block mb-2">
                      <input
                        type="radio"
                        name={`question-${index}`}
                        value={optionIndex}
                        checked={userAnswers[index] === optionIndex}
                        onChange={() => handleAnswerSelect(index, optionIndex)}
                        className="mr-2"
                      />
                      <span className="text-sm md:text-base">{option}</span>
                    </label>
                  ))}
                </div>
              ))}

              <div className="flex flex-col md:flex-row justify-between">
                <GlowingButton onClick={handleSubmit} className="text-sm md:text-base mb-2 md:mb-0">
                  Submit Quiz
                </GlowingButton>
                <NeonButton onClick={() => setShowMCQ(false)} className="text-sm md:text-base">
                  Close
                </NeonButton>
              </div>

              {score !== null && (
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-4 text-xl md:text-2xl font-normalText"
                >
                  Your score: {score} out of {Object.keys(project.mcq).filter(key => key.startsWith('question_')).length}
                </motion.p>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <style jsx>{`
        @keyframes glow {
          0% { box-shadow: 0 0 5px #FEF158; }
          50% { box-shadow: 0 0 20px #FEF158, 0 0 30px #FEF158; }
          100% { box-shadow: 0 0 5px #FEF158; }
        }
        .glow-effect {
          animation: glow 2s infinite;
        }
        .milestone-tree {
          padding: 20px 0;
        }
        .milestone-icon {
          z-index: 10;
        }
      `}</style>
      
    </motion.div>
    
  );
};

export default ProjectDetailsPage;