import React, { useState, useEffect, useRef } from 'react';
import { FaUser, FaInfoCircle, FaMap, FaBars, FaTimes, FaHome, FaSignInAlt } from 'react-icons/fa';
import { motion, AnimatePresence, useScroll, useTransform, useMotionValueEvent } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/slices/authSlice';

const NavLink = ({ to, children, icon, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link
        to={to}
        className={`font-display text-xl cursor-pointer py-2 px-3 rounded-md transition-all duration-200 flex items-center gap-2 ${isActive ? 'bg-[#FEF15833] text-[#FEF158]' : 'hover:bg-[#FEF15811] hover:text-[#FEF158]'
          }`}
        onClick={onClick}
      >
        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
        >
          {icon}
        </motion.div>
        <motion.span
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          {children}
        </motion.span>
      </Link>
    </motion.div>
  );
};

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);  // Add this line
  const { scrollY } = useScroll();
  const lastScrollY = useRef(0);
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const navbarBackground = useTransform(
    scrollY,
    [0, 50],
    ['rgba(35, 35, 32, 0.8)', 'rgba(35, 35, 32, 1)']  // Changed initial opacity to 0.8
  );

  useEffect(() => {
    const unsubscribe = scrollY.onChange(latest => {
      setIsScrolled(latest > 50);
    });
    return () => unsubscribe();
  }, [scrollY]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('authToken');
    navigate('/');
  };

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest < lastScrollY.current) {
      // Scrolling up
      setIsVisible(true);
    } else if (latest > 50 && latest > lastScrollY.current) {
      // Scrolling down and past the threshold
      setIsVisible(false);
    }
    lastScrollY.current = latest;
  });

  return (
    <>
      <motion.nav
        style={{ backgroundColor: navbarBackground }}
        className={`fixed top-0 left-0 right-0 z-[100] text-[#E0E0E0] transition-all duration-300 bg-[#232320]`}
        initial={{ y: 0 }}
        animate={{ y: isVisible ? 0 : '-100%' }}
        transition={{ duration: 0.3 }}
      >
        <div className="container mx-auto h-20 px-4 py-4 flex items-center justify-between">
          <motion.div
            className="flex items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link to="/" className="flex items-center">
              <motion.img
                src={logo}
                alt="Taksh Logo"
                className="h-12 w-auto"
                initial={{ rotate: -180, opacity: 0 }}
                animate={{ rotate: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
              <div className="flex flex-col ml-2">
                <motion.h1
                  className="font-display font-bold text-3xl text-[#FEF158]"
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  Taksh
                </motion.h1>
              </div>
              <motion.span
                className="text-md font-large text-[#00BCD4] tracking-wider ml-6 relative"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                style={{
                  textShadow: '0 0 2px #00BCD4, 0 0 4px #00BCD4',
                }}
              >
                <span className="relative z-10">BETA</span>
                <span
                  className="absolute inset-0 bg-[#00BCD4] opacity-20 blur-[2px] z-0"
                  style={{
                    animation: 'flicker 1.5s infinite alternate'
                  }}
                ></span>
                <style jsx>{`
                  @keyframes flicker {
                    0%, 18%, 22%, 25%, 53%, 57%, 100% {
                      opacity: 0.2;
                    }
                    20%, 24%, 55% {
                      opacity: 0.4;
                    }
                  }
                `}</style>
              </motion.span>
            </Link>
          </motion.div>

          {/* Desktop Menu */}
          <motion.div
            className="hidden md:flex items-center gap-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <NavLink to="/" icon={<FaHome />}>Home</NavLink>
            <NavLink to="/map" icon={<FaMap />}>Map</NavLink>
            <NavLink to="/profile" icon={<FaUser />}>Profile</NavLink>
            <NavLink to="/about" icon={<FaInfoCircle />}>About</NavLink>
          </motion.div>

          {/* Login/Logout Button */}
          {user ? (
            <motion.div
              className="hidden md:flex items-center space-x-4"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <img
                src={user.profilePicture || 'https://img.playbook.com/KL50jPCjNz1Y8faMyEG1r-kGm5Fz8Wz0O32LBlNZObY/Z3M6Ly9wbGF5Ym9v/ay1hc3NldHMtcHVi/bGljLzA3OGQ1OTk4/LTg3YWEtNDIzMS05/YmMxLTYxZWU0MDA4/NjlkMg'}
                alt="Profile"
                className="w-10 h-10 rounded-full cursor-pointer"
                onClick={() => navigate('/profile')}
              />
              <span className="text-[#FEF158]">{user.username}</span>
              <motion.button
                className="bg-[#2C3E50] text-[#FEF158] px-5 py-2.5 rounded-full text-base font-bold shadow-lg transform transition"
                whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(254, 241, 88, 0.3)" }}
                whileTap={{ scale: 0.95 }}
                onClick={handleLogout}
              >
                Logout
              </motion.button>
            </motion.div>
          ) : (
            <div className="hidden md:flex items-center space-x-3">
              <motion.button
                className="bg-[#2C3E50] text-[#FEF158] px-5 py-2.5 rounded-full text-base font-bold shadow-lg transform transition"
                whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(254, 241, 88, 0.3)" }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                onClick={() => navigate('/login')}
              >
                <FaSignInAlt className="inline-block mr-2" /> Login
              </motion.button>
              <motion.button
                className="bg-gradient-to-r from-[#FEF158] to-[#00BCD4] text-[#232320] px-5 py-2.5 rounded-full text-base font-bold shadow-lg transform transition"
                whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(0, 188, 212, 0.7)" }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                onClick={() => navigate('/signup')}
              >
                Sign Up
              </motion.button>
            </div>
          )}

          {/* Mobile Menu Button and Profile Picture */}
          <div className="md:hidden flex items-center space-x-2">
            {user && (
              <img
                src={user.profilePicture || 'https://img.playbook.com/KL50jPCjNz1Y8faMyEG1r-kGm5Fz8Wz0O32LBlNZObY/Z3M6Ly9wbGF5Ym9v/ay1hc3NldHMtcHVi/bGljLzA3OGQ1OTk4/LTg3YWEtNDIzMS05/YmMxLTYxZWU0MDA4/NjlkMg'}
                alt="Profile"
                className="w-8 h-8 rounded-full cursor-pointer"
                onClick={() => navigate('/profile')}
              />
            )}
            <motion.button
              className="bg-gradient-to-r from-[#FEF158] to-[#00BCD4] text-[#232320] p-2 rounded-full"
              onClick={toggleMenu}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, rotate: -180 }}
              animate={{ opacity: 1, rotate: 0 }}
              transition={{ duration: 0.5 }}
            >
              {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </motion.button>
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="md:hidden bg-[#232320] px-4 py-2"
            >
              <motion.div
                className="flex flex-col gap-2"
                variants={{
                  open: {
                    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
                  },
                  closed: {
                    transition: { staggerChildren: 0.05, staggerDirection: -1 }
                  }
                }}
                initial="closed"
                animate="open"
              >
                <NavLink to="/" icon={<FaHome />} onClick={toggleMenu}>Home</NavLink>
                <NavLink to="/map" icon={<FaMap />} onClick={toggleMenu}>Map</NavLink>
                <NavLink to="/profile" icon={<FaUser />} onClick={toggleMenu}>Profile</NavLink>
                <NavLink to="/about" icon={<FaInfoCircle />} onClick={toggleMenu}>About</NavLink>
                {!user && (
                  <div className="flex flex-col space-y-2 mt-4">
                    <motion.button
                      className="bg-[#2C3E50] text-[#FEF158] px-5 py-2.5 rounded-full text-base font-bold shadow-lg transform transition w-full"
                      whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(254, 241, 88, 0.3)" }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                        navigate('/login');
                        setIsMenuOpen(false);
                      }}
                    >
                      <FaSignInAlt className="inline-block mr-2" /> Login
                    </motion.button>
                    <motion.button
                      className="bg-gradient-to-r from-[#FEF158] to-[#00BCD4] text-[#232320] px-5 py-2.5 rounded-full text-base font-bold shadow-lg transform transition w-full"
                      whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(0, 188, 212, 0.7)" }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                        navigate('/signup');
                        setIsMenuOpen(false);
                      }}
                    >
                      Sign Up
                    </motion.button>
                  </div>
                )}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.nav>
      <style jsx>{`
        @keyframes pulse {
          0%, 100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
      `}</style>
    </>
  );
}