import React from 'react'
import styles from '../../styles/SubmitButton.module.css'
import { motion, AnimatePresence } from 'framer-motion'

const SubmitButton = ({ onClick, isValid, isSubmitting }) => {
    const buttonVariants = {
        initial: { width: '100%', height: 50, borderRadius: 25 },
        animate: {
            width: '100%',
            height: 50,
            borderRadius: 25,
            transition: { duration: 3 }
        }
    }

    const textVariants = {
        initial: { opacity: 1 },
        animate: { opacity: 0, transition: { duration: 2 } }  // Increased duration
    }

    const progressVariants = {
        initial: { width: 0 },
        animate: {
            width: '100%',
            transition: { duration: 1.5, ease: "easeInOut" }
        }
    }

    const checkVariants = {
        initial: { scale: 0, opacity: 0 },
        animate: {
            scale: 1,
            opacity: 1,
            transition: { delay: 1.5, duration: 1, ease: "easeInOut" }  // Increased duration
        }
    }

    return (
        <motion.button
            className={`${styles.button} ${!isValid ? styles.disabled : ''}`}
            variants={buttonVariants}
            initial="initial"
            animate={isSubmitting ? "animate" : "initial"}
            onClick={onClick}
            whileHover={isValid ? { scale: 1.03 } : {}}
            whileTap={isValid ? { scale: 0.98 } : {}}
            disabled={!isValid}
        >
            <AnimatePresence>
                {!isSubmitting && (
                    <motion.span
                        className={styles.text}
                        variants={textVariants}
                        initial="initial"
                        exit="animate"
                    >
                        Submit Feedback
                    </motion.span>
                )}
            </AnimatePresence>
            {isSubmitting && (
                <>
                    <motion.div
                        className={styles.progressBar}
                        variants={progressVariants}
                        initial="initial"
                        animate="animate"
                    />
                    <motion.div
                        className={styles.checkmark}
                        variants={checkVariants}
                        initial="initial"
                        animate="animate"
                    >
                        <div className={styles.finalText}>Submitted</div>
                    </motion.div>
                </>
            )}
        </motion.button>
    )
}

export default SubmitButton