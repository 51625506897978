import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import "../styles/Map.css";
import pole from '../assets/images/milestone.png';
import FeedbackModal from '../components/molecules/FeedbackModal';
import FloatingButton from '../components/molecules/FloatingButton';

const LEVELS = [
  { id: 1, name: 'Level 1', gridArea: 'levelone' },
  { id: 2, name: 'Level 2', gridArea: 'leveltwo' },
  { id: 3, name: 'Level 3', gridArea: 'levelthree' },
  { id: 4, name: 'Level 4', gridArea: 'levelfour' },
  { id: 5, name: 'Level 5', gridArea: 'levelfive' },
  { id: 6, name: 'Level 6', gridArea: 'levelsix' },
  { id: 7, name: 'Level 7', gridArea: 'levelseven' },
];

export default function MapPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const mapRef = useRef(null);

  const pageName = "MapPage";
  const projectTitle = "Map";

  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  const handleLinkClick = useCallback((id, event) => {
    event.preventDefault();
    setTimeout(() => {
      navigate('/projects', { state: { levelId: id } });
    }, 100);
  }, [navigate]);

  const drawLines = useCallback(() => {
    const boxes = document.querySelectorAll(".point");
    const svg = document.querySelector('#svgLines');
    if (!svg) return;

    const svgNS = 'http://www.w3.org/2000/svg';
    const points = Array.from(boxes).map(box => {
      const rect = box.getBoundingClientRect();
      return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2 + window.pageYOffset - 80
      };
    });

    svg.innerHTML = '';

    for (let i = 0; i < points.length - 1; i++) {
      const path = document.createElementNS(svgNS, 'path');
      const d = `M ${points[i].x},${points[i].y} L ${points[i + 1].x},${points[i + 1].y}`;
      path.setAttribute('d', d);
      path.setAttribute('stroke', '#d4d4d47b');
      path.setAttribute('stroke-width', '3');
      path.setAttribute('stroke-dasharray', '10, 10');
      path.setAttribute('stroke-linecap', 'round');
      path.setAttribute('fill', 'none');
      svg.appendChild(path);
    }
  }, []);

  useEffect(() => {
    drawLines();
    window.addEventListener("resize", drawLines);
    window.addEventListener("scroll", drawLines);

    // Check if we should scroll to center
    if (location.state && location.state.scrollToCenter) {
      if (mapRef.current) {
        const yOffset = -window.innerHeight / 2 + mapRef.current.clientHeight / 2;
        const y = mapRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }

    return () => {
      window.removeEventListener("resize", drawLines);
      window.removeEventListener("scroll", drawLines);
    };
  }, [drawLines, location.state]);

  // useEffect(() => {
  //   if (mapRef.current) {
  //     const yOffset = -64; // Adjust this value to account for any fixed headers
  //     const y = mapRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
  //     window.scrollTo({ top: y, behavior: 'smooth' });
  //   }
  // }, []);

  return (
    <div ref={mapRef} className="grid-mountain">
      {LEVELS.map((level) => (
        <div key={level.id} className={level.gridArea}>
          <Link
            to="/projects"
            className="flex items-center"
            onClick={(event) => handleLinkClick(level.id, event)}
            aria-label={`Navigate to ${level.name}`}
          >
            <div className="but-one">
              <img className={`location location-${level.id}`} src={pole} alt="" />
              <p className={`l font-display d${level.id}`}>{level.name}</p>
              <div className="point"></div>
            </div>
          </Link>
        </div>
      ))}
      <svg id="svgLines" aria-hidden="true"></svg>
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={closeFeedbackModal}
        pageName={pageName}
        projecttitle={projectTitle}
      />
      <FloatingButton
        onClick={openFeedbackModal}
        isFeedbackModalOpen={isFeedbackModalOpen}
      />
    </div>
  );
}
