import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, ThumbsUp, Lightbulb, AlertTriangle } from 'lucide-react'
import SubmitButton from './SubmitButton'

const StarRating = ({ rating, onRatingChange }) => {
    const [hoveredRating, setHoveredRating] = useState(0);

    return (
        <div className="flex gap-2">
            {[1, 2, 3, 4, 5].map((star) => (
                <motion.span
                    key={star}
                    className={`text-2xl cursor-pointer ${star <= (hoveredRating || rating) ? 'text-[#FEF158]' : 'text-[#E0E0E0]'}`}
                    onClick={() => onRatingChange(star)}
                    onMouseEnter={() => setHoveredRating(star)}
                    onMouseLeave={() => setHoveredRating(0)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    ★
                </motion.span>
            ))}
        </div>
    );
};

const FeedbackTypeButton = ({ icon: Icon, label, selected, onClick }) => (
    <motion.button
        className={`flex flex-col items-center p-2 sm:p-4 border-2 rounded-xl cursor-pointer transition-all duration-300 ease-in-out ${selected
                ? 'bg-[rgba(254,241,88,0.1)] border-[#FEF158] shadow-[0_0_15px_rgba(254,241,88,0.2)]'
                : 'border-[rgba(254,241,88,0.3)] bg-[rgba(35,35,32,0.5)]'
            }`}
        onClick={onClick}
        whileHover={{ scale: 1.05, y: -5 }}
        whileTap={{ scale: 0.95 }}
    >
        <Icon size={20} className={selected ? 'text-[#FEF158]' : 'text-[#E0E0E0]'} />
        <span className={`mt-1 text-xs sm:text-sm ${selected ? 'text-[#FEF158]' : 'text-[#E0E0E0]'}`}>{label}</span>
    </motion.button>
);
const FeedbackModal = ({ isOpen, onClose, pageName, projecttitle }) => {


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [feedback, setFeedback] = useState('')
    const [rating, setRating] = useState(0)
    const [feedbackType, setFeedbackType] = useState('')
    const [progress, setProgress] = useState(0)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [feedbackSent, setFeedbackSent] = useState(false)
    const [cooldownTime, setCooldownTime] = useState(0)

    // Use refs to store the initial prop values
    const initialPageName = useRef(pageName)
    const initialProjectTitle = useRef(projecttitle)

    useEffect(() => {
        // Update refs if props change and are not undefined
        if (pageName !== undefined) initialPageName.current = pageName
        if (projecttitle !== undefined) initialProjectTitle.current = projecttitle

        // console.log('FeedbackModal props:', { 
        //     isOpen, 
        //     pageName: initialPageName.current, 
        //     projecttitle: initialProjectTitle.current 
        // });
    }, [isOpen, pageName, projecttitle])


    useEffect(() => {
        const filledFields = [name, email, feedback, rating, feedbackType].filter(Boolean).length
        setProgress((filledFields / 5) * 100)
    }, [name, email, feedback, rating, feedbackType])

    useEffect(() => {
        let timer
        if (cooldownTime > 0) {
            timer = setTimeout(() => setCooldownTime(cooldownTime - 1), 1000)
        }
        return () => clearTimeout(timer)
    }, [cooldownTime])

    // console.log('FeedbackModal props after timer count useeffect:', { isOpen, pageName, projecttitle })

    const isFormValid = name && email && feedback && rating > 0 && feedbackType

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isFormValid && cooldownTime === 0) {
            setIsSubmitting(true);

            const feedbackData = {
                name,
                email,
                feedback,
                rating,
                type: feedbackType,
                page: initialPageName.current || 'General',
                projecttitle: initialProjectTitle.current || null
            };
            // console.log('Submitting feedback data:', feedbackData);
            try {
                const response = await fetch("https://script.google.com/macros/s/AKfycbwhJiViGIXYTGU5lqr1QuI9B5Z6SsyJfS1LCf5ZbqsKDqKGE7llTBrtHYZu-q-uwacZyg/exec", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(feedbackData),
                    mode: "no-cors"
                });
                setFeedbackSent(true);
                setIsSubmitting(false);
                setCooldownTime(60); // Set 60 seconds cooldown

                setTimeout(() => {
                    onClose();
                    setFeedbackSent(false);
                    setName('');
                    setEmail('');
                    setFeedback('');
                    setRating(0);
                    setFeedbackType('');
                }, 2000); // Close modal after 2 seconds

            } catch (error) {
                // console.error("Error submitting feedback:", error);
                setIsSubmitting(false);
            }
        }
    };

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.95, y: 20 },
        visible: {
            opacity: 1,
            scale: 1,
            y: 0,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 30,
                mass: 0.5,
                duration: 0.3
            }
        },
        exit: {
            opacity: 0,
            scale: 0.95,
            y: 20,
            transition: {
                duration: 0.2
            }
        }
    }

    const contentVariants = {
        hidden: { opacity: 0, y: 10 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'spring',
                stiffness: 500,
                damping: 30,
                mass: 0.5,
                duration: 0.3
            }
        }
    }

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 bg-[rgba(35,35,32,0.8)] backdrop-blur-[10px] flex items-center justify-center z-50 p-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <motion.div
                        className="bg-[rgba(44,62,80,0.95)] p-4 sm:p-6 rounded-3xl w-full max-w-md shadow-[0_20px_40px_rgba(0,0,0,0.3)] max-h-[90vh] overflow-y-auto"
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <div className="flex justify-between items-center mb-4">
                            <motion.h2
                                className="text-xl sm:text-2xl font-bold text-[#FEF158] font-display"
                                variants={contentVariants}
                            >
                                Your Feedback
                            </motion.h2>
                            <motion.button
                                className="text-[#E0E0E0] hover:text-[#FEF158] transition-colors duration-200"
                                onClick={onClose}
                                whileHover={{ scale: 1.05, rotate: 90 }}
                                whileTap={{ scale: 0.95 }}
                                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                            >
                                <X size={20} />
                            </motion.button>
                        </div>
                        <motion.div
                            className="h-1 bg-[rgba(224,224,224,0.2)] rounded-full mb-4 overflow-hidden"
                            initial={{ width: 0 }}
                            animate={{ width: '100%' }}
                            transition={{ duration: 0.3 }}
                        >
                            <motion.div
                                className="h-full bg-gradient-to-r from-[#FEF158] to-[#00BCD4]"
                                style={{ width: `${progress}%` }}
                                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                            />
                        </motion.div>
                        <form onSubmit={handleSubmit} className="space-y-3">
                            <motion.div variants={contentVariants}>
                                <label className="block mb-1 text-sm font-medium text-[#E0E0E0]">Feedback Type</label>
                                <div className="grid grid-cols-3 gap-2">
                                    <FeedbackTypeButton
                                        icon={ThumbsUp}
                                        label="I like it"
                                        selected={feedbackType === 'like'}
                                        onClick={() => setFeedbackType('like')}
                                    />
                                    <FeedbackTypeButton
                                        icon={Lightbulb}
                                        label="I have an idea"
                                        selected={feedbackType === 'idea'}
                                        onClick={() => setFeedbackType('idea')}
                                    />
                                    <FeedbackTypeButton
                                        icon={AlertTriangle}
                                        label="Not working"
                                        selected={feedbackType === 'issue'}
                                        onClick={() => setFeedbackType('issue')}
                                    />
                                </div>
                            </motion.div>
                            <motion.div variants={contentVariants}>
                                <label htmlFor="name" className="block mb-1 text-sm font-medium text-[#E0E0E0]">Name</label>
                                <motion.input
                                    type="text"
                                    id="name"
                                    className="w-full text-sm p-2 rounded-lg border border-[#E0E0E0] text-[#E0E0E0] bg-[rgba(35,35,32,0.5)] focus:outline-none focus:border-[#FEF158] focus:ring-2 focus:ring-[rgba(254,241,88,0.1)] transition-all duration-200"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    whileFocus={{ scale: 1.01 }}
                                    transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                                />
                            </motion.div>
                            <motion.div variants={contentVariants}>
                                <label htmlFor="email" className="block mb-1 text-sm font-medium text-[#E0E0E0]">Email</label>
                                <motion.input
                                    type="email"
                                    id="email"
                                    className="w-full text-sm p-2 rounded-lg border border-[#E0E0E0] text-[#E0E0E0] bg-[rgba(35,35,32,0.5)] focus:outline-none focus:border-[#FEF158] focus:ring-2 focus:ring-[rgba(254,241,88,0.1)] transition-all duration-200"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    whileFocus={{ scale: 1.01 }}
                                    transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                                />
                            </motion.div>
                            <motion.div variants={contentVariants}>
                                <label htmlFor="feedback" className="block mb-1 text-sm font-medium text-[#E0E0E0]">Feedback</label>
                                <motion.textarea
                                    id="feedback"
                                    className="w-full text-sm p-2 rounded-lg border border-[#E0E0E0] text-[#E0E0E0] bg-[rgba(35,35,32,0.5)] focus:outline-none focus:border-[#FEF158] focus:ring-2 focus:ring-[rgba(254,241,88,0.1)] transition-all duration-200 min-h-[60px] resize-y"
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                    required
                                    whileFocus={{ scale: 1.01 }}
                                    transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                                />
                            </motion.div>
                            <motion.div variants={contentVariants}>
                                <label className="block mb-1 text-sm font-medium text-[#E0E0E0]">Rating</label>
                                <StarRating rating={rating} onRatingChange={setRating} />
                            </motion.div>
                            <SubmitButton onClick={handleSubmit} isValid={isFormValid && cooldownTime === 0} isSubmitting={isSubmitting} />
                        </form>
                        {feedbackSent && (
                            <motion.div className="mt-3 p-2 bg-[rgba(254,241,88,0.1)] border-2 border-[#FEF158] rounded-lg text-sm text-[#FEF158] text-center" variants={contentVariants}>
                                Feedback sent successfully!
                            </motion.div>
                        )}
                        {cooldownTime > 0 && (
                            <motion.div className="mt-3 text-sm text-[#E0E0E0] text-center" variants={contentVariants}>
                                Please wait {cooldownTime} seconds before submitting another feedback.
                            </motion.div>
                        )}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default FeedbackModal

// The pageName prop is expected to be passed when rendering the FeedbackModal component. Here's how you would use it in different pages:
// In the General page (e.g., LandingPage.js)
//
// <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal} pageName="General" />

// In the ProjectDisplay page
//
// <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal} pageName="ProjectDisplay" />

// In the ProjectDetails page
//
// <FeedbackModal isOpen={isFeedbackModalOoen} onClose={closeFeedbackModal} pageName="ProjectDetails" projecttitle="Title" />

// In the Profile page
//
// <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal} pageName="ProfilePage" />

