import React from 'react';
import { motion } from 'framer-motion';
import styles from '../../styles/projectcard.module.css';
import { FaClock } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const ProjectCard = ({ project }) => {
  const { id, lvl, title, oneliner, time, image } = project;

  return (
    <Link to={`/level/${lvl}/project/${id}/`}>
      <motion.div
        className={styles['project-card']}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
      >
        <img src={image} alt={title} className={styles.thumbnail} />
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{oneliner}</p>
          <span className={styles.time}>
            <p><FaClock /> {time}</p>
          </span>
        </div>
      </motion.div>
    </Link>
  );
};

export default ProjectCard;


// import React from 'react';
// import { motion } from 'framer-motion';
// import { FaClock } from "react-icons/fa6";
// import { Link } from 'react-router-dom';

// // Uncomment this section when you are ready to fetch real data from an API
// // const ProjectCard = ({ project }) => {
// //   const { id, lvl, title, oneliner, time, image } = project;

// const ProjectCard = () => {
//   // Hardcoded data for testing purposes
//   const id = 1;
//   const lvl = 1;
//   const title = "Potentiometer with Arduino to Control LED Brightness";
//   const oneliner = "Teaching your Arduino to message your computer its sensor gossip!";
//   const time = "47 mins";
//   const image = "https://csg.tinkercad.com/things/ePzYdJtTC6F/t725.png?rev=1675156096364000000&s=&v=1&type=circuits";

//   return (
//     <Link to={`/level/${lvl}/project/${id}/`}>
//       <motion.div
//         className="bg-gradient-to-r from-[#2C3E50] to-[#34495E] rounded-lg overflow-hidden shadow-lg"
//         whileHover={{ scale: 1.05 }}
//         whileTap={{ scale: 0.95 }}
//         transition={{ type: 'spring', stiffness: 400, damping: 17 }}
//       >
//         <img src={image} alt={title} className="w-full h-48 object-cover" />
//         <div className="p-6">
//           <h3 className="text-2xl font-display text-[#FEF158] mb-2">{title}</h3>
//           <p className="text-[#E0E0E0] font-normalText mb-4">{oneliner}</p>
//           <span className="flex items-center text-[#00BCD4] font-normalText">
//             <FaClock className="mr-2" /> {time}
//           </span>
//         </div>
//       </motion.div>
//     </Link>
//   );
// };

// export default ProjectCard;