import axios from 'axios';

const API_BASE_URL = 'https://gameoftaksh.live';  // Update this to use HTTPS and your domain
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,  // This is important for sending cookies
});

// Function to get CSRF token from cookies
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// Add a request interceptor
api.interceptors.request.use(function (config) {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Add a response interceptor for token refresh
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newToken = await refreshToken();
            if (newToken) {
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                return api(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

export const getLevel = (levelOrUrl) => {
    if (typeof levelOrUrl === 'string' && levelOrUrl.startsWith('http')) {
        return axios.get(levelOrUrl);
    }
    return api.get(`/api/level/${levelOrUrl}/`);
};

export const getProjects = (lvl, id) => api.get(`/api/level/${lvl}/project/${id}/`);

export const signUp = async (userData) => {
    try {
        const response = await api.post('/api/auth/registration/', userData);
        return response;
    } catch (error) {
        console.error('Signup error:', error);
        throw error;
    }
};

export const login = async (userData) => {
    try {
        const response = await api.post('/api/token/', {
            email: userData.email,
            password: userData.password
        });
        if (response.data.access) {
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            api.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;
        }
        return response;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

export const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
        try {
            const response = await api.post('/api/token/refresh/', { refresh: refreshToken });
            if (response.data.access) {
                localStorage.setItem('access_token', response.data.access);
                api.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;
            }
            return response.data.access;
        } catch (error) {
            console.error('Error refreshing token:', error);
            // Handle refresh token expiration (e.g., logout user)
        }
    }
    return null;
};

export const logout = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            await api.post('/api/token/blacklist/', { refresh: refreshToken });
        }
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        delete api.defaults.headers['Authorization'];
    } catch (error) {
        console.error('Logout error:', error);
    }
};

export const getUserProfile = async (token) => {
  try {
    const response = await api.get('/api/profile/', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};
