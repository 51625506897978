import React from 'react';
import { FaLinkedin, FaXTwitter, FaDiscord, FaYoutube, FaInstagram, FaArrowUp } from 'react-icons/fa6';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

const FooterLink = ({ to, children }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(to);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.li
      whileHover={{ scale: 1.05, x: 5 }}
      whileTap={{ scale: 0.95 }}
    >
      <a
        href={to}
        onClick={handleClick}
        className="text-[#86868b] hover:text-[#FEF158] transition-colors duration-300 text-sm"
      >
        {children}
      </a>
    </motion.li>
  );
};

const SocialLink = ({ href, icon: Icon, label }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-[#86868b] hover:text-[#FEF158] transition-colors duration-300"
    whileHover={{ scale: 1.2, rotate: 5 }}
    whileTap={{ scale: 0.9 }}
    aria-label={label}
  >
    <Icon size={24} />
  </motion.a>
);

const Footer = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer ref={ref} className="bg-gradient-to-b from-[#1d1d1f] to-[#121212] text-[#86868b] py-12 sm:py-16 z-[20]"> {/* Added relative and z-50 */}
      <AnimatePresence>
        {inView && (
          <motion.div
            className="container mx-auto px-4"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-8 mb-12">
              <div className="grid grid-cols-3 gap-4 md:gap-8 max-w-3xl w-full">
                <motion.div variants={itemVariants} className="space-y-4">
                  <h3 className="font-semibold text-[#FEF158] mb-2 text-base sm:text-lg text-center md:text-left">Learn</h3>
                  <ul className="space-y-2 text-sm sm:text-base text-center md:text-left">
                    <FooterLink to="/projects">Projects</FooterLink>
                    <FooterLink to="/tutorials">Tutorials</FooterLink>
                    <FooterLink to="/resources">Resources</FooterLink>
                    <FooterLink to="/blog">Blog</FooterLink>
                  </ul>
                </motion.div>
                <motion.div variants={itemVariants} className="space-y-4">
                  <h3 className="font-semibold text-[#FEF158] mb-2 text-base sm:text-lg text-center md:text-left">About</h3>
                  <ul className="space-y-2 text-sm sm:text-base text-center md:text-left">
                    <FooterLink to="/about">Our Story</FooterLink>
                    <FooterLink to="/team">Team</FooterLink>
                    <FooterLink to="/careers">Careers</FooterLink>
                    <FooterLink to="/press">Press Kit</FooterLink>
                  </ul>
                </motion.div>
                <motion.div variants={itemVariants} className="space-y-4">
                  <h3 className="font-semibold text-[#FEF158] mb-2 text-base sm:text-lg text-center md:text-left">Support</h3>
                  <ul className="space-y-2 text-sm sm:text-base text-center md:text-left">
                    <FooterLink to="/faq">FAQ</FooterLink>
                    <FooterLink to="/contact">Contact Us</FooterLink>
                    <FooterLink to="/privacy">Privacy</FooterLink>
                    <FooterLink to="/terms">Terms</FooterLink>
                  </ul>
                </motion.div>
              </div>
              <motion.div variants={itemVariants} className="space-y-4 w-full md:w-auto md:max-w-xs">
                <h3 className="font-semibold text-[#FEF158] mb-2 text-lg text-center md:text-left">Join Us</h3>
                <div className="flex justify-center md:justify-start flex-wrap gap-4 mb-4">
                  <SocialLink href="https://www.linkedin.com/company/taksh-at-khoj" icon={FaLinkedin} label="LinkedIn" />
                  <SocialLink href="https://x.com/gameoftaksh" icon={FaXTwitter} label="X (Twitter)" />
                  <SocialLink href="https://discord.gg/WtcbkkgA" icon={FaDiscord} label="Discord" />
                  <SocialLink href="https://www.youtube.com/@gameoftaksh" icon={FaYoutube} label="YouTube" />
                  <SocialLink href="https://www.instagram.com/gameoftaksh" icon={FaInstagram} label="Instagram" />
                </div>
                <motion.div
                  className="mt-4"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <p className="text-[#E0E0E0] mb-2 text-sm sm:text-base text-center md:text-left">
                    Ready to start your journey?
                  </p>
                  <div className="flex flex-col sm:flex-row gap-2">
                    <motion.button
                      onClick={() => handleNavigation('/login')}
                      className="bg-[#2c2c2e] text-[#FEF158] px-4 py-2 rounded-md hover:bg-[#3c3c3e] transition-colors duration-300 text-center"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Login
                    </motion.button>
                    <motion.button
                      onClick={() => handleNavigation('/signup')}
                      className="bg-[#FEF158] text-[#1d1d1f] px-4 py-2 rounded-md hover:bg-[#00BCD4] transition-colors duration-300 text-center"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Sign Up
                    </motion.button>
                  </div>
                </motion.div>
              </motion.div>
            </div>
            <motion.div
              className="border-t border-[#424245] pt-6 mt-6 text-sm flex flex-col sm:flex-row justify-between items-center"
              variants={itemVariants}
            >
              <p className="mb-4 sm:mb-0 text-center sm:text-left">&copy; {new Date().getFullYear()} Taksh. All rights reserved.</p>
              <div className="flex flex-wrap justify-center sm:justify-end gap-4">
                <FooterLink to="/terms">Terms of Use</FooterLink>
                <FooterLink to="/privacy">Privacy Policy</FooterLink>
                <FooterLink to="/cookies">Cookie Policy</FooterLink>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </footer>
  );
};

export default Footer;